import { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../components/Navbar";
import ScrollToTop from "../components/ScrollTopTop";
import Footer from "../components/Footer";

export default function Root() {
  return (
    <>
      <div className="flex flex-col min-h-screen bg-neutral-950">
        <div className="flex-grow flex flex-col items-center justify-between w-full px-4">
          <div className="w-full max-w-[1145px]">
            <NavBar />
          </div>
          <div className="w-full max-w-[1145px] flex-grow flex flex-col justify-center">
            <Outlet />
          </div>
          <div className="w-full max-w-[1145px]">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
