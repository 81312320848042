import { URL_API } from "../constants/api";
import Api from "./api";

export const apiGetDataHome = async () => {
  const reqUrl = URL_API.GET_DATA_HOME;

  const res = await Api.get(reqUrl, {});
  //console.log("res", res);
  return res;
};

export const apiGetMinerWithAddress = async (address: String) => {
  const reqUrl = URL_API.GET_MINER_BY_ADDRESS + address;

  const res = await Api.get(reqUrl, {});
  //console.log("res", res);
  return res;
};

export const apiGetWorkerByMinerWithAddress = async (address: String) => {
  const reqUrl = URL_API.GET_WORKER_STATS_BY_MINER + address;

  const res = await Api.get(reqUrl, {});
  //console.log("res", res);
  return res;
};

export const apiGetWorkerActvieByMinerWithAddress = async (address: String) => {
  const reqUrl =
    URL_API.GET_WORKER_ACTIVE_BY_MINER + address + "/active-workers";

  const res = await Api.get(reqUrl, {});
  //console.log("res", res);
  return res;
};

export const apiGetPayoutRewardByMinerWithAddress = async (address: String) => {
  const reqUrl = URL_API.GET_PAYOUT_REWARDS_BY_MINER + address;

  const res = await Api.get(reqUrl, {});
  //console.log("res", res);
  return res;
};

export const apiGetTransactionsByMinerWithAddress = async (address: String) => {
  const reqUrl = URL_API.GET_TRANSACTIONS_BY_MINER + address;

  const res = await Api.get(reqUrl, {});
  //console.log("res", res);
  return res;
};
