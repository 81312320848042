import React, { useState } from "react";
import "./App.css";
import Home from "./pages/Home";
import ProtectedRouter from "./auth/ProtectedRouter";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import DataContext from "./context/DataContext";
import "react-toastify/dist/ReactToastify.css";
import Mining from "./pages/Mining";
import MinerDashboard from "./pages/MinerDashboard";
import MinerPayout from "./pages/MinerPayout";
import NotFound from "./pages/NotFound";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRouter />,
    children: [
      { path: "", element: <Home /> },
      { path: "/mining", element: <Mining /> },
      { path: "/miner/:address/dashboard", element: <MinerDashboard /> },
      { path: "/miner/:address/payout", element: <MinerPayout /> },
      { path: "/404", element: <NotFound /> },
      { path: "*", element: <NotFound /> },
    ],
  },
]);

function App() {
  const [user, setUser] = useState(null);
  const [botConnection, setBotConnection] = useState<WebSocket | null>(null);
  const [dataServer, setDataServer] = useState(null);

  return (
    <DataContext.Provider
      value={{
        user,
        setUser,
        dataServer,
        setDataServer,
      }}
    >
      <div className="font-ChivoMono w-full h-full min-h-screen">
        <RouterProvider router={router} />
      </div>
    </DataContext.Provider>
  );
}

export default App;
