import icon_ore from "../assets/icon-ore.svg";

function NotFound() {
  return (
    <div className="flex items-center justify-center min-h-screen w-full">
      <div className="flex items-center text-8xl font-medium text-white max-md:text-4xl">
        <span>4</span>
        <img
          loading="lazy"
          src={icon_ore}
          className="object-contain w-16 h-16 mx-2"
          alt="ORE icon"
        />
        <span>4 not found</span>
      </div>
    </div>
  );
}

export default NotFound;
