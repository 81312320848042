import React, { useState, useEffect } from "react";
import icon_search from "../assets/icon-search.svg";
import { useNavigate } from "react-router-dom";

const placeholderTexts = [
  "Enter ORE miner address",
  "Type your miner ID here",
  "Input mining address",
];

interface TypingPlaceholderProps {
  texts: string[];
  typingSpeed?: number;
  deletingSpeed?: number;
  pauseDuration?: number;
}

const TypingPlaceholder: React.FC<TypingPlaceholderProps> = ({
  texts,
  typingSpeed = 100,
  deletingSpeed = 50,
  pauseDuration = 2000,
}) => {
  const [placeholderText, setPlaceholderText] = useState("");
  const [textIndex, setTextIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isTyping) {
      if (placeholderText.length < texts[textIndex].length) {
        timer = setTimeout(() => {
          setPlaceholderText(
            texts[textIndex].slice(0, placeholderText.length + 1)
          );
        }, typingSpeed);
      } else {
        setIsTyping(false);
        timer = setTimeout(() => setIsTyping(false), pauseDuration);
      }
    } else {
      if (placeholderText.length > 0) {
        timer = setTimeout(() => {
          setPlaceholderText(placeholderText.slice(0, -1));
        }, deletingSpeed);
      } else {
        setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setIsTyping(true);
      }
    }
    return () => clearTimeout(timer);
  }, [
    placeholderText,
    isTyping,
    textIndex,
    texts,
    typingSpeed,
    deletingSpeed,
    pauseDuration,
  ]);

  return <>{placeholderText}</>;
};

const OreMinerInput: React.FC = () => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onSearch = () => {
    if (inputValue) {
      navigate(`/miner/${inputValue}/dashboard`);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  return (
    <div className="relative w-full max-w-md">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className="w-full px-4 py-2 text-sm text-white bg-transparent border border-neutral-400 focus:outline-none focus:border-neutral-300 rounded-tl-none rounded-br-none rounded-tr-lg rounded-bl-lg"
        placeholder=" "
      />
      {inputValue === "" && (
        <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none text-neutral-400">
          <TypingPlaceholder texts={placeholderTexts} />
        </div>
      )}
      <div className="absolute inset-y-0 right-0 flex items-center pr-4">
        <img
          onClick={onSearch}
          loading="lazy"
          src={icon_search}
          className="object-contain self-stretch my-auto w-6 aspect-square cursor-pointer"
          alt="search"
        />
      </div>
    </div>
  );
};

export default OreMinerInput;
