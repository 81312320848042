import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";

export default function NavBar() {
  const navigate = useNavigate();

  const gotoMining = () => {
    window.open("https://mineore.app/download", "_blank");
  };
  const gotoHome = () => {
    navigate("/");
  };

  return (
    <>
      <div className="flex flex-wrap gap-10 justify-between items-center w-full text-base font-semibold text-black max-w-[1145px] max-md:max-w-full">
        <img
          onClick={gotoHome}
          loading="lazy"
          src={logo}
          className="object-contain shrink-0 self-stretch my-auto aspect-[3.92] w-[120px] sm:w-[157px] cursor-pointer"
        />
        <div className="flex gap-4 items-center self-stretch my-auto">
          <div
            onClick={gotoMining}
            className="gap-2.5 self-stretch px-4 py-2 my-auto bg-white text-black cursor-pointer text-sm sm:text-base transition-colors duration-300 ease-in-out hover:bg-gray-800 hover:text-white"
          >
            Start Mining
          </div>
        </div>
      </div>
    </>
  );
}
