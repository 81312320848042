import React from "react";
import icon_github from "../assets/icon-github.svg";
import icon_discord from "../assets/icon-discord.svg";

export default function Footer() {
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-wrap gap-8 justify-center items-start w-full">
        <a
          href="https://github.com/MineORE"
          target="_blank"
          rel="noopener noreferrer"
          className="transition-transform duration-300 hover:scale-110"
        >
          <img
            loading="lazy"
            src={icon_github}
            alt="GitHub"
            className="object-contain shrink-0 w-8 aspect-square"
          />
        </a>
        <a
          href="https://discord.gg/your-discord-invite"
          target="_blank"
          rel="noopener noreferrer"
          className="transition-transform duration-300 hover:scale-110"
        >
          <img
            loading="lazy"
            src={icon_discord}
            alt="Discord"
            className="object-contain shrink-0 w-8 aspect-square"
          />
        </a>
      </div>
      <div className="gap-2.5 self-stretch mt-4 w-full text-[0.6rem] sm:text-xs text-center leading-6 text-neutral-400 max-md:max-w-full">
        Copyright MineORE © 2024. All rights reserved.
      </div>
    </div>
  );
}
