import { ValuePropsGetUser } from "../../utils/interface.global";
import { URL_API } from "../constants/api";
import Api from "./api";

export const loginUserByAPI = async (props: ValuePropsGetUser) => {
  const reqUrl = URL_API.LOGIN;

  const res = await Api.post(reqUrl, props);
  console.log("res", res.data);
  return res.data;
};

// export const loadDataUserByAPI = async (props: any) => {
//   const reqUrl = URL_API.GET_USER;

//   const res = await Api.post(reqUrl, props);
//   //console.log("res", res.data);
//   return res.data;
// };

// export const getLuckydrawByAPI = async (props: any) => {
//   const reqUrl = URL_API.LUCKYDRAW;

//   const res = await Api.post(reqUrl, props);
//   //console.log("res", res.data);
//   return res.data;
// };
