import * as React from "react";

function Mining() {
  return (
    <div className="flex flex-col justify-center w-full h-full">
      <div className="text-3xl font-bold text-white max-md:max-w-full">
        How to mine
      </div>
      <div className="flex flex-col mt-8 max-w-full w-[877px]">
        <div className="text-lg md:text-xl font-semibold text-white max-md:max-w-full">
          1. Download the miner
        </div>
        <div className="text-sm md:text-lg font-normal text-[#8D8D8D] max-md:max-w-full">
          MineORE CLI is available using the following command:
        </div>
        <div className="gap-2.5 self-start px-4 py-2 mt-2 text-sm md:text-base text-black bg-white max-md:max-w-full">
          cargo install mineore-cli
        </div>
      </div>
      <div className="flex flex-col mt-8 max-w-full w-[877px]">
        <div className="text-lg md:text-xl font-semibold text-white max-md:max-w-full">
          2. Start your miner
        </div>
        <div className="text-sm md:text-lg font-normal text-[#8D8D8D] max-md:max-w-full">
          Get started using the following command:
        </div>
        <div className="gap-2.5 self-stretch px-4 py-2 mt-2 w-full text-sm md:text-base text-black bg-white max-md:max-w-full">
          mineore --address YOUR_ADDRESS mine --cores NUMBER_OF_CORES
        </div>
      </div>
      <div className="flex flex-col mt-8 max-w-full w-[877px]">
        <div className="text-lg md:text-xl font-semibold text-white">
          3. Invite your friends & earn ORE
        </div>
        <div className="text-sm md:text-lg font-normal text-[#8D8D8D] max-md:max-w-full">
          Share your invitation code with your friends and earn more ORE
        </div>
        <div className="gap-2.5 self-stretch px-4 py-2 mt-2 w-full text-sm md:text-base text-black bg-white">
          --invitation-code CODE_INVITE
        </div>
      </div>
    </div>
  );
}

export default Mining;
