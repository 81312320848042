import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getUserData } from "../redux/slices/userSlices";
import useAuth from "../hooks/useAuthentication";
import Root from "../pages/Root";

const ProtectedRouter = () => {
  const userData = useSelector(getUserData);

  const hasFreePull = useMemo(() => {
    if (userData) return true;
    return false;
  }, [userData]);
  const isAuthed: boolean = useAuth();

  return <Root />;
  //return isAuthed ? <Root /> : <Navigate to={"/"} replace />;
};

export default ProtectedRouter;
