export function formatDateTime(date: Date): string {
  const pad = (num: number) => num.toString().padStart(2, "0");

  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1); // Months are 0-indexed
  const year = date.getFullYear();

  return `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;
}

export function formatLocalDateTime(isoString: string): string {
  // Tạo đối tượng Date từ chuỗi ISO
  const date = new Date(isoString);

  // Lấy các thành phần của ngày giờ local
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Tạo chuỗi theo định dạng yyyy-mm-dd H:i:s
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function formatNumber(num: number, decimalPlaces: number = 0): string {
  return num.toLocaleString(navigator.language, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
}

export function formatNumberEarnings(
  reward: string,
  decimalPlaces: number = 7
): string {
  return (parseFloat(reward) / 100000000000).toLocaleString(
    navigator.language,
    {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    }
  );
}

export function checkAndShowData(value: any) {
  if (value) {
    return value;
  } else {
    return "";
  }
}

export function isDateAGreaterThanB(dateA: string, dateB: string): boolean {
  return new Date(dateA) > new Date(dateB);
}

export function showFormatLastPayout(date: string): string {
  const now = new Date();
  const payoutDate = new Date(date);
  const diffInMilliseconds = now.getTime() - payoutDate.getTime();
  const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInMinutes < 60) {
    return `${diffInMinutes}m`;
  } else if (diffInHours < 24) {
    return `${diffInHours}h`;
  } else {
    return `${diffInDays}d`;
  }
}
