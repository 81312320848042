import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OreMinerInput from "../components/OreMinerInput";
import icon_search from "../assets/icon-search.svg";
import icon_ore from "../assets/icon-ore.svg";
import icon_sort_asc from "../assets/icon-sort-1.svg";
import icon_sort_desc from "../assets/icon-sort-2.svg";
import icon_pagi_first from "../assets/icon-pagi-first.svg";
import icon_pagi_back from "../assets/icon-pagi-back.svg";
import icon_pagi_next from "../assets/icon-pagi-next.svg";
import icon_pagi_last from "../assets/icon-pagi-last.svg";
import {
  checkAndShowData,
  formatDateTime,
  formatLocalDateTime,
  formatNumber,
  formatNumberEarnings,
} from "../utils/utils";
import CountUp from "react-countup";
import {
  apiGetMinerWithAddress,
  apiGetWorkerActvieByMinerWithAddress,
  apiGetWorkerByMinerWithAddress,
} from "../components/api/miner";
import { DATA_MINER } from "../utils/interface.global";

interface Worker {
  name: string;
  hashrate: number;
  average_earning_per_hour: string;
  average_earning_per_day: string;
  lastSeen: string;
}

function MinerDashboard() {
  const navigate = useNavigate();
  const { address } = useParams<{ address: string }>();
  const [inputValue, setInputValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [workers, setWorkers] = useState<Worker[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [workersPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof Worker;
    direction: "asc" | "desc";
  } | null>(null);

  const [headers, setHeaders] = useState([
    { name: "Name", key: "name" as keyof Worker, icon: icon_sort_asc },
    { name: "Hashrate", key: "hashrate" as keyof Worker, icon: icon_sort_desc },
    {
      name: "Est. Earnings/Hr",
      key: "average_earning_per_hour" as keyof Worker,
      icon: icon_sort_asc,
    },
    {
      name: "Est. Earnings//Day",
      key: "average_earning_per_day" as keyof Worker,
      icon: icon_sort_asc,
    },
    { name: "Last Seen", key: "lastSeen" as keyof Worker, icon: icon_sort_asc },
  ]);

  const [dataMiner, setDataMiner] = useState<DATA_MINER>({
    miner_address: "",
    invitation_code: "",
    invited_by: "",
    referral_count: 0,
    referral_reward: "0",
    total_pending_reward: "0",
    total_paid_reward: "0",
    created_at: "",
    updated_at: "",
    estimated_daily_earnings: "0",
    hashrate: 0,
  });

  useEffect(() => {
    const getData = async () => {
      await getDataForWorkerWithWallet();
      await getListWorkers();
    };
    getData();
  }, [address]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onSearch = () => {
    if (inputValue) {
      // Implement search logic here
      console.log("Searching for:", inputValue);
      setSearchTerm(inputValue);
    } else {
      setSearchTerm("");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const sortedWorkers = React.useMemo(() => {
    let sortableWorkers = [...workers];

    if (searchTerm) {
      sortableWorkers = sortableWorkers.filter((worker) =>
        worker.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (sortConfig !== null) {
      sortableWorkers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableWorkers;
  }, [workers, sortConfig, searchTerm]);

  const requestSort = (key: keyof Worker) => {
    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    setHeaders(
      headers.map((header) =>
        header.key === key
          ? {
              ...header,
              icon: direction === "asc" ? icon_sort_asc : icon_sort_desc,
            }
          : header
      )
    );
  };

  // Get current workers
  const indexOfLastWorker = currentPage * workersPerPage;
  const indexOfFirstWorker = indexOfLastWorker - workersPerPage;
  const currentWorkers = sortedWorkers.slice(
    indexOfFirstWorker,
    indexOfLastWorker
  );

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const gotoPayout = () => {
    navigate(`/miner/${address}/payout`);
  };

  const getDataForWorkerWithWallet = async () => {
    if (address) {
      const result = await apiGetMinerWithAddress(address);
      //console.log("apiGetMinerWithAddress", result);
      if (result.status == 200) {
        setDataMiner((prevState) => ({
          ...prevState,
          ...result.data,
        }));
      } else if (result.status == 404) {
        navigate(`/404`);
      }
    } else {
      navigate(`/404`);
    }
  };

  const getListWorkers = async () => {
    if (address) {
      const result = await apiGetWorkerActvieByMinerWithAddress(address);
      console.log("apiGetWorkerActvieByMinerWithAddress", result);
      if (result.status == 200) {
        let hashrate: number = 0;

        let mockData: Worker[] = [];
        for (let tmp of result.data.active_workers) {
          const item: Worker = {
            name: tmp.worker_name,
            hashrate: tmp.total_shares / 55,
            average_earning_per_day: tmp.average_earning_per_day,
            average_earning_per_hour: tmp.average_earning_per_hour,
            lastSeen: tmp.last_seen_active,
          };

          mockData.push(item);

          hashrate += item.hashrate;
        }
        setWorkers(mockData);

        setDataMiner((prevState) => ({
          ...prevState,
          total_pending_reward: result.data.total_pending_reward,
          estimated_daily_earnings: result.data.estimated_daily_earnings,
          hashrate: hashrate,
        }));
      }
    }
  };

  const getDecimalSeparator = (locale: string) => {
    const numberWithDecimalSeparator = 1.1;
    const decimalPart = Intl.NumberFormat(locale)
      .formatToParts(numberWithDecimalSeparator)
      .find((part) => part.type === "decimal");
    return decimalPart ? decimalPart.value : "";
  };

  const getThousandsSeparator = (locale: string) => {
    const number = 1000;
    return Intl.NumberFormat(locale).format(number).replace(/\d/g, "");
  };

  return (
    <>
      {dataMiner && dataMiner.miner_address != "" ? (
        <div className="flex flex-col mt-10 mb-10">
          <div className="flex flex-wrap gap-10 justify-between items-start w-full max-md:max-w-full">
            <div className="text-3xl font-bold text-white">Miner Address</div>
            <div className="flex flex-col min-w-full sm:min-w-[450px] max-md:max-w-full">
              <OreMinerInput />
            </div>
          </div>
          <div className="flex flex-col mt-8 w-full max-md:max-w-full">
            <div className="flex flex-wrap gap-5 justify-between items-center w-full text-base whitespace-nowrap max-md:max-w-full">
              <div className="gap-2.5 self-stretch px-4 py-2.5 my-auto text-xs sm:text-base text-black whitespace-nowrap bg-white min-h-[37px] min-w-[240px] w-[455px] max-md:max-w-full">
                {address}
              </div>
              <div className="flex items-center self-stretch my-auto min-w-[240px]">
                <div className="gap-2.5 self-stretch px-4 pl-0 sm:pl-4 py-2.5 my-auto text-xs sm:text-base text-white min-h-[37px]">
                  Invitation Code:
                </div>
                <div className="gap-2.5 self-stretch px-4 py-2.5 my-auto text-xs sm:text-base text-black whitespace-nowrap bg-white min-h-[37px]">
                  {checkAndShowData(dataMiner.invitation_code)}
                </div>
              </div>
              <div className="flex self-stretch my-auto font-semibold border border-solid border-neutral-400 w-full sm:w-auto">
                <div className="flex-1 sm:flex-none gap-2.5 self-stretch px-4 py-2 text-black bg-white text-center sm:w-[120px] cursor-pointer transition-colors duration-300 hover:bg-gray-200">
                  Dashboard
                </div>
                <div
                  onClick={gotoPayout}
                  className="flex-1 sm:flex-none self-stretch px-4 py-2 text-white text-center sm:w-[120px] cursor-pointer transition-colors duration-300 hover:bg-gray-700"
                >
                  Payout
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-10 justify-between items-start p-4 mt-4 w-full text-white border-t border-b border-white max-md:max-w-full">
              <div className="flex flex-col w-[135px]">
                <div className="text-base">Total Referral</div>
                <div className="flex-1 shrink gap-2 self-stretch mt-2 w-full text-3xl whitespace-nowrap font-BrunoAce">
                  <CountUp
                    end={checkAndShowData(dataMiner.referral_count)}
                    duration={2.5}
                  />
                </div>
              </div>
              <div className="flex flex-col whitespace-nowrap w-[173px]">
                <div className="text-base">Hashrate</div>
                <div className="gap-2 self-stretch mt-2 w-full text-3xl font-BrunoAce">
                  <CountUp
                    end={checkAndShowData(dataMiner.hashrate)}
                    duration={2.5}
                    separator={getThousandsSeparator(navigator.language)}
                  />
                  /s
                </div>
              </div>
              <div className="flex flex-col w-[216px]">
                <div className="text-base">Pending rewards</div>
                <div className="flex gap-2 items-center mt-2 w-full text-3xl whitespace-nowrap font-BrunoAce">
                  <div className="self-stretch my-auto">
                    <CountUp
                      end={checkAndShowData(
                        dataMiner.total_pending_reward
                          ? formatNumberEarnings(dataMiner.total_pending_reward)
                          : 0
                      )}
                      duration={2.5}
                      decimals={5}
                    />
                  </div>
                  <div className="flex items-center self-stretch my-auto">
                    <img
                      loading="lazy"
                      src={icon_ore}
                      className="object-contain shrink-0 self-stretch my-auto aspect-[1.04] w-[25px]"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col min-w-[240px] w-[292px]">
                <div className="text-base">Estimated Earnings</div>
                <div className="flex gap-4 items-center mt-2 w-full text-3xl whitespace-nowrap font-BrunoAce">
                  <div className="self-stretch my-auto">
                    <CountUp
                      end={checkAndShowData(
                        formatNumberEarnings(
                          (
                            parseFloat(dataMiner.estimated_daily_earnings) *
                            24 *
                            55
                          ).toString()
                        )
                      )}
                      duration={2.5}
                      decimals={6}
                    />
                  </div>
                  <div className="flex items-center self-stretch my-auto">
                    <img
                      loading="lazy"
                      src={icon_ore}
                      className="object-contain shrink-0 self-stretch my-auto aspect-[1.04] w-[25px]"
                    />
                    <div className="self-stretch my-auto">/day</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap gap-10 justify-between items-center mt-8 w-full max-md:max-w-full">
            <div className="self-stretch my-auto text-xl font-semibold text-white">
              Active workers: {formatNumber(workers.length)}
            </div>
            <div className="flex flex-col self-stretch my-auto min-w-full sm:min-w-[450px] max-md:max-w-full">
              <div className="relative w-full max-w-md max-md:max-w-full">
                <input
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  className="w-full px-4 py-2 text-white bg-transparent border border-neutral-400 focus:outline-none focus:border-neutral-300 rounded-tl-none rounded-br-none rounded-tr-lg rounded-bl-lg"
                  placeholder="Search worker"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-4">
                  <img
                    onClick={onSearch}
                    loading="lazy"
                    src={icon_search}
                    className="object-contain self-stretch my-auto w-6 aspect-square cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-black text-white overflow-x-auto mt-8">
            <div className="flex flex-col min-w-full sm:min-w-[400px] max-md:max-w-full text-[0.54rem] sm:text-base">
              {/* Table Header */}
              <div className="flex border-b border-gray-700">
                {headers.map((header, index) => (
                  <div
                    key={index}
                    className={`flex-1 p-1 sm:p-2 ${
                      header.key == "lastSeen"
                        ? "text-center sm:text-right justify-center sm:justify-end"
                        : "text-center sm:text-left justify-center sm:justify-start"
                    } flex flex-col sm:flex-row items-center cursor-pointer`}
                    onClick={() => requestSort(header.key)}
                  >
                    <img
                      src={header.icon}
                      alt="sort"
                      className="w-4 h-4 ml-1 block sm:hidden"
                    />
                    <span>{header.name}</span>
                    <img
                      src={header.icon}
                      alt="sort"
                      className="w-4 h-4 ml-1 hidden sm:block"
                    />
                  </div>
                ))}
              </div>

              {/* Table Body */}
              {currentWorkers.map((worker, index) => (
                <div
                  key={index}
                  className="flex border-b border-gray-700 font-normal"
                >
                  <div className="flex-1 p-1 sm:p-2 text-center sm:text-left">
                    {worker.name}
                  </div>
                  <div className="flex-1 p-1 sm:p-2 text-center sm:text-left">
                    {formatNumber(worker.hashrate, 2)}
                  </div>
                  <div className="flex-1 p-1 sm:p-2 text-center sm:text-left">
                    {formatNumberEarnings(worker.average_earning_per_hour)}
                  </div>
                  <div className="flex-1 p-1 sm:p-2 text-center sm:text-left">
                    {formatNumberEarnings(worker.average_earning_per_day)}
                  </div>
                  <div className="flex-1 p-1 sm:p-2 text-center sm:text-right">
                    {formatLocalDateTime(worker.lastSeen)}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-end mt-8 w-full whitespace-nowrap max-md:max-w-full">
            <div className="flex items-center gap-2 text-white">
              <img
                loading="lazy"
                src={icon_pagi_first}
                className="w-8 h-8 p-1 rounded cursor-pointer hover:bg-gray-700 transition-colors"
                alt="First page"
                onClick={() => paginate(1)}
              />
              <img
                loading="lazy"
                src={icon_pagi_back}
                className="w-8 h-8 p-1 rounded cursor-pointer hover:bg-gray-700 transition-colors"
                alt="Previous page"
                onClick={() => paginate(Math.max(1, currentPage - 1))}
              />
              <div className="px-2">
                {currentPage}/{Math.ceil(workers.length / workersPerPage)}
              </div>
              <img
                loading="lazy"
                src={icon_pagi_next}
                className="w-8 h-8 p-1 rounded cursor-pointer hover:bg-gray-700 transition-colors"
                alt="Next page"
                onClick={() =>
                  paginate(
                    Math.min(
                      Math.ceil(workers.length / workersPerPage),
                      currentPage + 1
                    )
                  )
                }
              />
              <img
                loading="lazy"
                src={icon_pagi_last}
                className="w-8 h-8 p-1 rounded cursor-pointer hover:bg-gray-700 transition-colors"
                alt="Last page"
                onClick={() =>
                  paginate(Math.ceil(workers.length / workersPerPage))
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-center min-h-screen w-full">
            <div className="flex items-center text-3xl font-medium text-white max-md:text-4xl">
              <span>Loading data...</span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default MinerDashboard;
