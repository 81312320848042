import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OreMinerInput from "../components/OreMinerInput";

import icon_ore from "../assets/icon-ore.svg";
import icon_sort_asc from "../assets/icon-sort-1.svg";
import icon_sort_desc from "../assets/icon-sort-2.svg";
import icon_pagi_first from "../assets/icon-pagi-first.svg";
import icon_pagi_back from "../assets/icon-pagi-back.svg";
import icon_pagi_next from "../assets/icon-pagi-next.svg";
import icon_pagi_last from "../assets/icon-pagi-last.svg";
import {
  checkAndShowData,
  formatDateTime,
  formatLocalDateTime,
  formatNumberEarnings,
  isDateAGreaterThanB,
  showFormatLastPayout,
} from "../utils/utils";
import CountUp from "react-countup";
import {
  apiGetMinerWithAddress,
  apiGetPayoutRewardByMinerWithAddress,
  apiGetTransactionsByMinerWithAddress,
} from "../components/api/miner";
import { DATA_MINER } from "../utils/interface.global";

interface Payout {
  paidon: string;
  amount: string;
  txhash: string;
}

function MinerPayout() {
  const navigate = useNavigate();
  const { address } = useParams<{ address: string }>();
  const [inputValue, setInputValue] = useState("");
  const [payouts, setPayouts] = useState<Payout[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [workersPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState<{
    key: keyof Payout;
    direction: "asc" | "desc";
  } | null>(null);

  const [headers, setHeaders] = useState([
    { name: "Paid on", key: "paidon" as keyof Payout, icon: icon_sort_asc },
    {
      name: "Amount",
      key: "amount" as keyof Payout,
      icon: icon_sort_asc,
    },
    {
      name: "Txhash",
      key: "txhash" as keyof Payout,
      icon: null,
    },
  ]);

  const [lastPayout, setLastPayout] = useState("");
  const [dailyEarnings, setDailyEarnings] = useState(0);

  const [dataMiner, setDataMiner] = useState<DATA_MINER>({
    miner_address: "",
    invitation_code: "",
    invited_by: "",
    referral_count: 0,
    referral_reward: "0",
    total_pending_reward: "0",
    total_paid_reward: "0",
    estimated_daily_earnings: "0",
    hashrate: 0,
    created_at: "",
    updated_at: "",
  });

  useEffect(() => {
    const getData = async () => {
      await getDataForWorkerWithWallet();
      await getListPayout();
    };
    getData();
  }, [address]);

  useEffect(() => {
    // Simulate API call to fetch workers data
    const fetchWorkers = async () => {
      // In a real app, you would fetch data from an API here
      const mockData: Payout[] = Array.from({ length: 50 }, (_, index) => ({
        paidon: formatDateTime(new Date()),
        difficulty: (Math.random() * 34).toFixed(0),
        amount: (Math.random() * 100000).toFixed(3),
        txhash:
          "3NMfeboHryYpvJMBUgnVhr3DLN6bz9VpZSaDo92eXGtjsbPovYx39sNrBCCgKu3r2bCNRumn1KYLFvSaZ9viVmHe",
      }));
      setPayouts(mockData);
    };

    fetchWorkers();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const onSearch = () => {
    if (inputValue) {
      // Implement search logic here
      console.log("Searching for:", inputValue);
    }
  };

  const sortedPayouts = React.useMemo(() => {
    let sortablePayouts = [...payouts];
    if (sortConfig !== null) {
      sortablePayouts.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablePayouts;
  }, [payouts, sortConfig]);

  const requestSort = (key: keyof Payout) => {
    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    setHeaders(
      headers.map((header) =>
        header.key === key
          ? {
              ...header,
              icon: direction === "asc" ? icon_sort_asc : icon_sort_desc,
            }
          : header
      )
    );
  };

  // Get current workers
  const indexOfLastWorker = currentPage * workersPerPage;
  const indexOfFirstWorker = indexOfLastWorker - workersPerPage;
  const currentWorkers = sortedPayouts.slice(
    indexOfFirstWorker,
    indexOfLastWorker
  );

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const gotoDashboard = () => {
    navigate(`/miner/${address}/dashboard`);
  };
  const truncateHash = (hash: string) => {
    if (hash.length <= 9) return hash; // If hash is too short, return as is
    return `${hash.substring(0, 3)}...${hash.substring(hash.length - 3)}`;
  };

  const getDataForWorkerWithWallet = async () => {
    if (address) {
      const result = await apiGetMinerWithAddress(address);
      console.log("apiGetMinerWithAddress", result);
      if (result.status == 200) {
        setDataMiner((prevState) => ({
          ...prevState,
          ...result.data,
        }));
      } else if (result.status == 404) {
        navigate(`/404`);
      }
    } else {
      navigate(`/404`);
    }
  };

  const getListPayout = async () => {
    if (address) {
      const result = await apiGetTransactionsByMinerWithAddress(address);
      console.log("apiGetTransactionsByMinerWithAddress", result);
      if (result.status == 200) {
        let mockData: Payout[] = [];
        let created_at = lastPayout;
        for (let tmp of result.data) {
          const item = {
            paidon: tmp.created_at,
            amount: tmp.amount,
            txhash: tmp.tx_hash,
          };
          if (
            created_at == "" ||
            isDateAGreaterThanB(tmp.created_at, created_at)
          ) {
            created_at = tmp.created_at;
          }
          mockData.push(item);
        }
        setPayouts(mockData);
        setLastPayout(created_at);
      }
    }
  };

  return (
    <>
      {dataMiner && dataMiner.miner_address != "" ? (
        <div className="flex flex-col mt-10 mb-10">
          <div className="flex flex-wrap gap-10 justify-between items-start w-full max-md:max-w-full">
            <div className="text-3xl font-bold text-white">Miner Address</div>
            <div className="flex flex-col min-w-full sm:min-w-[400px] max-md:max-w-full">
              <OreMinerInput />
            </div>
          </div>
          <div className="flex flex-col mt-8 w-full max-md:max-w-full">
            <div className="flex flex-wrap gap-5 justify-between items-center w-full text-base whitespace-nowrap max-md:max-w-full">
              <div className="gap-2.5 self-stretch px-4 py-2.5 my-auto text-xs sm:text-base text-black whitespace-nowrap bg-white min-h-[37px] min-w-[240px] w-[455px] max-md:max-w-full">
                {address}
              </div>
              <div className="flex items-center self-stretch my-auto min-w-[240px]">
                <div className="gap-2.5 self-stretch px-4 pl-0 sm:pl-4 py-2.5 my-auto text-xs sm:text-base text-white min-h-[37px]">
                  Invitation Code:
                </div>
                <div className="gap-2.5 self-stretch px-4 py-2.5 my-auto text-xs sm:text-base text-black whitespace-nowrap bg-white min-h-[37px]">
                  {checkAndShowData(dataMiner.invitation_code)}
                </div>
              </div>
              <div className="flex self-stretch my-auto font-semibold border border-solid border-neutral-400 w-full sm:w-auto">
                <div
                  onClick={gotoDashboard}
                  className="flex-1 sm:flex-none gap-2.5 self-stretch px-4 py-2 text-white text-center sm:w-[120px] cursor-pointer transition-colors duration-300 hover:bg-gray-200"
                >
                  Dashboard
                </div>
                <div className="flex-1 sm:flex-none self-stretch px-4 py-2 text-black bg-white text-center sm:w-[120px] cursor-pointer transition-colors duration-300 hover:bg-gray-700">
                  Payout
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-10 justify-between items-start p-4 mt-4 w-full border-t border-b border-white max-md:max-w-full">
              <div className="flex flex-col text-white">
                <div className="text-base">Last payout</div>
                <div className="gap-2 self-start mt-2 text-3xl whitespace-nowrap">
                  {showFormatLastPayout(lastPayout)}
                </div>
              </div>
              <div className="flex flex-col w-[225px]">
                <div className="text-base text-white">Daily earnings</div>
                <div className="flex gap-4 items-center mt-2 w-full">
                  <div className="self-stretch my-auto text-3xl text-white">
                    {/* <CountUp end={dailyEarnings} duration={2} decimals={7} /> */}
                    ---
                  </div>
                  <div className="flex items-center self-stretch my-auto w-6">
                    <img
                      loading="lazy"
                      src={icon_ore}
                      className="object-contain self-stretch my-auto aspect-[1.04] w-[25px]"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-[225px]">
                <div className="text-base text-white">Pending rewards</div>
                <div className="flex gap-4 items-center mt-2 w-full">
                  <div className="self-stretch my-auto text-3xl text-white">
                    <CountUp
                      end={checkAndShowData(
                        dataMiner.total_pending_reward
                          ? parseInt(dataMiner.total_pending_reward) /
                              100000000000
                          : 0
                      )}
                      duration={2}
                      decimals={7}
                    />
                  </div>
                  <div className="flex items-center self-stretch my-auto w-6">
                    <img
                      loading="lazy"
                      src={icon_ore}
                      className="object-contain self-stretch my-auto aspect-[1.04] w-[25px]"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-[225px]">
                <div className="text-base text-white">Next payout</div>
                <div className="flex gap-4 items-center mt-2 w-full">
                  <div className="self-stretch my-auto text-3xl text-white">
                    <CountUp
                      end={checkAndShowData(
                        dataMiner.total_pending_reward
                          ? 0.02 -
                              parseInt(dataMiner.total_pending_reward) /
                                100000000000
                          : 0
                      )}
                      duration={2}
                      decimals={7}
                    />
                  </div>
                  <div className="flex items-center self-stretch my-auto w-6">
                    <img
                      loading="lazy"
                      src={icon_ore}
                      className="object-contain self-stretch my-auto aspect-[1.04] w-[25px]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-black text-white overflow-x-auto sm:overflow-x-visible mt-8">
            <div className="flex flex-col min-w-full sm:min-w-0">
              {/* Table Header */}
              <div className="flex border-b border-gray-700 text-[0.6rem] sm:text-xs md:text-sm lg:text-base">
                {headers.map((header, index) => (
                  <div
                    key={index}
                    className={`flex-1 p-1 sm:p-2 text-center sm:text-left justify-center sm:justify-start flex flex-col sm:flex-row items-center cursor-pointer`}
                    onClick={() => requestSort(header.key)}
                  >
                    {header.icon ? (
                      <img
                        src={header.icon}
                        alt="sort"
                        className="w-4 h-4 ml-1 block sm:hidden"
                      />
                    ) : (
                      <></>
                    )}
                    <span>{header.name}</span>
                    {header.icon ? (
                      <img
                        src={header.icon}
                        alt="sort"
                        className="w-4 h-4 ml-1 hidden sm:block"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </div>

              {/* Table Body */}
              {currentWorkers.map((worker, index) => (
                <div
                  key={index}
                  className="flex border-b border-gray-700 text-[0.6rem] sm:text-xs md:text-sm lg:text-base"
                >
                  <div className="flex-1 p-1 sm:p-2 text-center sm:text-left">
                    {formatLocalDateTime(worker.paidon)}
                  </div>
                  <div className="flex-1 p-1 sm:p-2 text-center sm:text-left">
                    {formatNumberEarnings(worker.amount)}
                  </div>
                  <div className="flex-1 p-1 sm:p-2 text-center sm:text-left overflow-hidden text-ellipsis">
                    <span className="sm:hidden">
                      {truncateHash(worker.txhash)}
                    </span>
                    <span className="hidden sm:inline">{worker.txhash}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-end mt-8 w-full whitespace-nowrap max-md:max-w-full">
            <div className="flex items-center gap-2 text-white">
              <div className="text-sm">Show</div>
              <img
                loading="lazy"
                src={icon_pagi_first}
                className="w-8 h-8 p-1 rounded cursor-pointer hover:bg-gray-700 transition-colors"
                alt="First page"
                onClick={() => paginate(1)}
              />
              <img
                loading="lazy"
                src={icon_pagi_back}
                className="w-8 h-8 p-1 rounded cursor-pointer hover:bg-gray-700 transition-colors"
                alt="Previous page"
                onClick={() => paginate(Math.max(1, currentPage - 1))}
              />
              <div className="px-2">
                {currentPage}/{Math.ceil(payouts.length / workersPerPage)}
              </div>
              <img
                loading="lazy"
                src={icon_pagi_next}
                className="w-8 h-8 p-1 rounded cursor-pointer hover:bg-gray-700 transition-colors"
                alt="Next page"
                onClick={() =>
                  paginate(
                    Math.min(
                      Math.ceil(payouts.length / workersPerPage),
                      currentPage + 1
                    )
                  )
                }
              />
              <img
                loading="lazy"
                src={icon_pagi_last}
                className="w-8 h-8 p-1 rounded cursor-pointer hover:bg-gray-700 transition-colors"
                alt="Last page"
                onClick={() =>
                  paginate(Math.ceil(payouts.length / workersPerPage))
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-center min-h-screen w-full">
            <div className="flex items-center text-3xl font-medium text-white max-md:text-4xl">
              <span>Loading data...</span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default MinerPayout;
