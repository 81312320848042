import { useEffect, useState } from "react";
import img from "../assets/img.png";
import OreMinerInput from "../components/OreMinerInput";
import CountUp from "react-countup";
import { apiGetDataHome } from "../components/api/miner";
import { DATA_HOME } from "../utils/interface.global";

function Home() {
  const [data, setData] = useState<DATA_HOME>({
    round_id: 0,
    total_hash_rate: 0,
    total_workers: 0,
    total_miners: 0,
  });
  useEffect(() => {
    getData();

    const intervalId = setInterval(getData, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const getData = async () => {
    const result = await apiGetDataHome();
    if (result.status === 200) {
      setData(result.data);
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center w-full h-full">
        <div className="flex flex-wrap gap-10 justify-between items-center w-full mb-16">
          <div className="flex flex-col self-stretch my-auto min-w-[240px] w-[500px] max-md:max-w-full">
            <div className="text-base text-white">
              Synergized mining power, maximized rewards|
            </div>
            <div className="flex flex-col mt-8 w-full max-w-[500px] max-md:max-w-full">
              <OreMinerInput />
            </div>
          </div>
          <div className="flex overflow-hidden flex-col self-stretch my-auto min-w-[240px] w-[500px] max-md:max-w-full hidden sm:block">
            <img
              loading="lazy"
              srcSet={img}
              className="object-contain w-full aspect-square max-md:max-w-full"
              alt="mining_ore"
            />
          </div>
        </div>
        <div className="grid grid-cols-3 sm:grid-cols-3 gap-2 sm:gap-8 w-full text-white mt-8 sm:mt-16">
          <div className="flex flex-col">
            <div className="text-sm sm:text-base text-center">
              Pool Hashrate
            </div>
            <div className="mt-1 sm:mt-2 text-sm sm:text-3xl text-center font-BrunoAce">
              <CountUp
                end={data.total_hash_rate}
                duration={2.5}
                separator=","
              />
              H/s
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-sm sm:text-base text-center">
              Active Miners
            </div>
            <div className="mt-1 sm:mt-2 text-sm sm:text-3xl text-center font-BrunoAce">
              <CountUp end={data.total_miners} duration={2.5} separator="," />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-sm sm:text-base  text-center">
              Active Workers
            </div>
            <div className="mt-1 sm:mt-2 text-sm sm:text-3xl text-center font-BrunoAce">
              <CountUp end={data.total_workers} duration={2.5} separator="," />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
